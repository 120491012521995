import * as React from 'react';
import { proxiTouchShop } from '../config';
import { Address, Card, Email, Social, Telephone } from '../types/Card';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme: Theme) => createStyles({

    root: {
        marginBottom: theme.spacing(2),
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        paddingRight: theme.spacing(3) + 'px !important',
        borderRight: '1px solid ' + theme.palette.action.selected,
    },
    data: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        paddingLeft: theme.spacing(3) + 'px !important',
    },
    footer: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        textAlign: 'center',
    },

}))

const CardDataTable = ({ cardData }: { cardData: Card }) => {

    const classes = useStyles();
    const { id: cardId, customerName, companyName, jobTitle, companyCategory,
        address, telephone, email, social, website, notes } = cardData;

    const _capitalise = (s: String) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    return (
        <>
            <Grid container spacing={2} className={classes.root}>
                {
                    customerName &&
                    <>
                        <Grid item xs={4} className={classes.label}>
                            Name
                        </Grid>
                        <Grid item xs={8} className={classes.data}>
                            {customerName}
                        </Grid>
                    </>
                }

                {
                    companyName &&
                    <>
                        <Grid item xs={4} className={classes.label}>
                            Company
                        </Grid>
                        <Grid item xs={8} className={classes.data}>
                            {companyName}
                        </Grid>
                    </>
                }

                {
                    jobTitle &&
                    <>
                        <Grid item xs={4} className={classes.label}>
                            Job Title
                        </Grid>
                        <Grid item xs={8} className={classes.data}>
                            {jobTitle}
                        </Grid>
                    </>
                }

                {
                    companyCategory &&
                    <>
                        <Grid item xs={4} className={classes.label}>
                            Company Cate.
                        </Grid>
                        <Grid item xs={8} className={classes.data}>
                            {companyCategory}
                        </Grid>
                    </>
                }

                {
                    address.length > 0 &&
                    address.map((addr: Address, i: number) => {
                        const isEmpty = Object.values(addr).every(el => el === null || el === '');
                        if (!isEmpty) {
                            return (
                                <React.Fragment key={i}>
                                    <Grid item xs={4} className={classes.label}>
                                        Address {i + 1}
                                    </Grid>
                                    <Grid item xs={8} className={classes.data}>
                                        {addr.line1 ? <>{addr.line1} <br /></> : null}
                                        {addr.line2 ? <>{addr.line2} <br /></> : null}
                                        {addr.line3 ? <>{addr.line3} <br /></> : null}
                                        {addr.city ? <>{addr.city} <br /></> : null}
                                        {addr.county ? <>{addr.county} <br /></> : null}
                                        {addr.country ? <>{addr.country} <br /></> : null}                                {addr.postcode}
                                    </Grid>
                                </React.Fragment>
                            )
                        } else {
                            return null;
                        }
                    })
                }

                {
                    telephone.length > 0 &&
                    telephone.map((tele: Telephone, i: number) =>
                        tele.number !== "" &&
                        <React.Fragment key={i}>
                            <Grid item xs={4} className={classes.label}>
                                {_capitalise(tele.type)} Phone
                            </Grid>
                            <Grid item xs={8} className={classes.data}>
                                {tele.number}
                            </Grid>
                        </React.Fragment>
                    )
                }

                {
                    email.length > 0 &&
                    email.map((em: Email, i: number) =>
                        em.type !== "paypal" && em.address !== "" &&
                        <React.Fragment key={i}>
                            <Grid item xs={4} className={classes.label}>
                                {_capitalise(em.type)} Email
                            </Grid>
                            <Grid item xs={8} className={classes.data}>
                                <a href={`mailto:${em.address}`}>{em.address}</a>
                            </Grid>
                        </React.Fragment>
                    )
                }

                {
                    website &&
                    <>
                        <Grid item xs={4} className={classes.label}>
                            Website
                        </Grid>
                        <Grid item xs={8} className={classes.data}>
                            <a href={website}>Website</a>
                        </Grid>
                    </>
                }

                {
                    social.map((soc: Social, i: number) => {
                        if (soc.url !== "") {
                            return (
                                <React.Fragment key={i}>
                                    <Grid item xs={4} className={classes.label}>
                                        {_capitalise(soc.type)}
                                    </Grid>
                                    <Grid item xs={8} className={classes.data}>
                                        <a href={soc.url}>{_capitalise(soc.type)}</a>
                                    </Grid>
                                </React.Fragment>
                            )
                        }
                    })
                }

                {
                    notes ?
                        <>
                            <Grid item xs={4} className={classes.label}>
                                Notes
                            </Grid>
                            <Grid item xs={8} className={classes.data}>
                                {notes}
                            </Grid>
                        </>
                        :
                        null
                }
            </Grid>
            <Divider />
            <Grid item xs={12} className={classes.footer}>
                Get your own <a href={`${proxiTouchShop}/products/proxicard?referral=${cardId}`}>ProxiCard</a>
            </Grid>
        </>
    )

}

export default CardDataTable;