import { apiRoot, version } from '../config';

const createUrl = (path: string) => {
    return `${apiRoot}/${version}/${path}`;
};

const call = <T extends {}>(req: Request): Promise<T> => {
    return fetch(req)
        .then(res => {
            if (!res.ok) {
                throw new Error("API Error");
            }
            return res.json() as Promise<T>
        })
        .then(response => {
            return response
        })
        .catch(error => {
            return error
        })
}


const API = {
    call,
    createUrl
}

export default API;