import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '../types/Card';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getCard } from '../helpers/cardHelper';
import CardDisplayWrapper from './CardDisplayWrapper';
import CardDataTable from './CardDataTable';
import CardDownload from './CardDownload';
import CardDownloadSuccess from './CardDownloadSuccess';


const useStyles = makeStyles((theme: Theme) => createStyles({

    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > .MuiCircularProgress-root': {
            '& > svg': {
                color: theme.palette.text.primary,
            }
        }
    },

}))

const CardDisplay = () => {

    const classes = useStyles();
    const { tkn } = useParams<Record<string, string>>()
    const [loading, setLoading] = useState<Boolean>(true);
    const [error, setError] = useState<Boolean>(false);
    const [cardData, setCardData] = useState<Card | undefined>(undefined);
    const [downloaded, setDownloaded] = useState<Boolean>(false);

    useEffect(() => {
        if (tkn !== "") {
            getCard(tkn)
                .then(result => {
                    setCardData(result);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error getting card data", error);
                    setError(true);
                    setLoading(false)
                })
        } else {
            setError(true);
            setLoading(false);
        }
    }, [tkn])

    if (loading) {
        return (
            <CardDisplayWrapper>
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            </CardDisplayWrapper>
        )
    } else if (error) {
        return (
            <CardDisplayWrapper>
                <Typography>
                    An error has occured
                </Typography>
            </CardDisplayWrapper>
        )
    } else {
        return (
            cardData ?
                <CardDisplayWrapper>
                    {
                        downloaded ?
                            <CardDownloadSuccess />
                            :
                            <>
                                <CardDownload cardData={cardData} onDownloaded={() => setDownloaded(true)} />
                                <CardDataTable cardData={cardData} />
                            </>
                    }
                </CardDisplayWrapper>
                :
                null
        )
    }

};

export default CardDisplay;