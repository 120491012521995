const isDev = false;

export let version = 'v1';
export let apiRoot: string;
export let proxiTouchShop: string;

if(isDev) {
    apiRoot = 'https://localhost:5001';
    proxiTouchShop = 'https://proxitouch.com';
} else {
    apiRoot = 'https://api.proxitouch.com';
    proxiTouchShop = 'https://proxitouch.com';
}
