import * as React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Logo from '../images/logo.png';

const useStyles = makeStyles((theme: Theme) => createStyles({

    logo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4), 
        width: '80%',
    },
    contentContainer: {
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
    }

}))


type CardDisplayWrapperProps = {
    children: React.ReactChildren | React.ReactChild,
}

const CardDisplayWrapper = ({ children }: CardDisplayWrapperProps) => {

    const classes = useStyles();

    return (
        <Container maxWidth='sm'>
            <img src={Logo} className={classes.logo} alt="ProxiTouch Logo" />
            <div className={classes.contentContainer}>
                { children }
            </div>
        </Container>
    )

}

export default CardDisplayWrapper;