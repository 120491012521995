import { useCallback, useEffect, useState } from 'react';
import Resizer from "react-image-file-resizer";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { proxiTouchShop } from '../config';
import { Card, Telephone, Social, Email, Address } from '../types/Card';

const vCardsJS = require('@proxitouch/vcards-js');

const resizeFile = (file: Blob) =>
    new Promise<string>((resolve) => {
        Resizer.imageFileResizer(
            file,
            256,
            256,
            "PNG",
            100,
            0,
            (uri) => {
                resolve(uri as string);
            },
            "base64"
        );
    });

const useStyles = makeStyles((theme: Theme) => createStyles({

    downloadButton: {
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.background.paper,
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
        marginBottom: theme.spacing(2),
        animation: '$pulse 2s infinite',
        '&:hover': {
            color: theme.palette.text.primary,
        }
    },
    '@keyframes pulse': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)'
        },
        '70%': {
            boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)'
        },
        '100%': {
            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
        }
    }

}))

const CardDownload = ({ cardData, onDownloaded }: { cardData: Card, onDownloaded: Function }) => {

    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState("");
    const [fileName, setFileName] = useState("");

    const getStreetAddressString = useCallback((address: Address): string => {
        let streetString = '';

        if (address.line1)
            streetString = address.line1;

        if (address.line2)
            streetString = streetString + '\n' + address.line2;

        if (address.line3)
            streetString = streetString + '\n' + address.line3;

        return streetString;
    }, []);

    const getvCard = useCallback(async () => {

        let vCard = new vCardsJS();

        let nameParts = cardData.customerName ? cardData.customerName.split(" ") : null;
        let workPhone = cardData.telephone.find((tel: Telephone) => tel.type.toLocaleLowerCase() === "work");
        let homePhone = cardData.telephone.find((tel: Telephone) => tel.type.toLocaleLowerCase() === "home");
        let mobilePhone = cardData.telephone.find((tel: Telephone) => tel.type.toLocaleLowerCase() === "personal");
        let workEmail = cardData.email.find((em: Email) => em.type.toLocaleLowerCase() === "work");
        let personalEmail = cardData.email.find((em: Email) => em.type.toLocaleLowerCase() === "personal");

        vCard.version = '3.0';

        if (cardData.companyName) {
            vCard.firstName = cardData.companyName
        }

        if (nameParts) {
            vCard.nickname = `${nameParts[0] ? nameParts[0] : ""} ${nameParts[1] ? nameParts[1] : ""}`;
        }

        if (cardData.jobTitle && cardData.jobTitle !== "")
            vCard.role = cardData.jobTitle;

        if (cardData.website && cardData.website !== "")
            vCard.url = cardData.website;

        if (workPhone && workPhone.number !== "")
            vCard.workPhone = workPhone.number;

        if (homePhone && homePhone.number !== "")
            vCard.homePhone = homePhone.number;

        if (mobilePhone && mobilePhone.number !== "")
            vCard.cellPhone = mobilePhone.number;

        if (workEmail && workEmail.address !== "")
            vCard.workEmail = workEmail.address;

        if (personalEmail && personalEmail.address !== "")
            vCard.email = personalEmail.address;

        cardData.social.forEach((soc: Social) => {
            if (soc.url !== "") {
                vCard.socialUrls[soc.type] = soc.url;
            }
        })

        vCard.note = cardData.notes ?
            cardData.notes + ` - Get your own proxicard: ${proxiTouchShop}/products/proxicard?referral=${cardData.id}`
            :
            `Get your own proxicard: ${proxiTouchShop}/products/proxicard?referral=${cardData.id}`;

        if (cardData.address.length > 0 && !Object.values(cardData.address[0]).every(el => el === null || el === '')) {
            vCard.workAddress.label = 'Work Address';
            vCard.workAddress.street = getStreetAddressString(cardData.address[0]);
            vCard.workAddress.city = cardData.address[0].city;
            vCard.workAddress.stateProvince = cardData.address[0].county;
            vCard.workAddress.postalCode = cardData.address[0].postcode;
            vCard.workAddress.countryRegion = cardData.address[0].country;
        }

        if (cardData.imageUrl) {
            try {
                const res = await fetch(cardData.imageUrl);
                const blob = await res.blob();
                const convertedBlob = await resizeFile(blob);
                const base64StringParts = convertedBlob.split(',');
                vCard.photo.embedFromString(base64StringParts[1], 'image/png');
                const data = new Blob([vCard.getFormattedString()], { type: "text/vcard;charset=utf-8" });
                const url = window.URL.createObjectURL(data);
                setUrl(url);
                setLoading(false);
            }
            catch (err) {
                console.error("Error getting/converting contact image", err);
                const data = new Blob([vCard.getFormattedString()], { type: "text/vcard;charset=utf-8" });
                const url = window.URL.createObjectURL(data);
                setUrl(url);
                setLoading(false);
            }
        } else {
            const data = new Blob([vCard.getFormattedString()], { type: "text/vcard;charset=utf-8" });
            const url = window.URL.createObjectURL(data);
            setUrl(url);
            setLoading(false);
        }

        setFileName(cardData.companyName);

    }, [cardData, getStreetAddressString])

    useEffect(() => {
        getvCard();
    }, [getvCard])

    return (
        <Button
            size='large'
            fullWidth
            variant='contained'
            href={url}
            download={fileName + '.vcf'}
            className={classes.downloadButton}
            disabled={loading}
            onClick={() => onDownloaded()}
        >
            Download as contact
        </Button>
    )
}

export default CardDownload;