import * as React from 'react';
import { 
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import CardDisplay from './components/CardDisplay';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/card/:tkn">
          <CardDisplay />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
